.cllctn-bx {
    border: 1px solid;
    overflow: hidden;
    border-radius: 5px;
    height: 98%;
    display: flex;
    flex-direction: column;
  }
  .cllctn-bx > img {
    margin-bottom: -7px;
    margin-left: auto;
    margin-right: auto;
    /* max-width: 65%; */
    max-width: 100%;
    transition: all 0.3s ease-in-out;
    max-height: 370px !important;
  
  }
  .cllctn-bx > img:hover {
    transform: scale(1.25);
  }
  .cllctn-title {
    position: relative;
    bottom: 0 !important;
    /* height: 94%; */
    height: 10%;
    bottom: 20px;
    background-color: grey;
    padding: 5px;
    display: flex;
    align-items: center;
    z-index: 1 !important;
  }

  .fade-load {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.visible{
  opacity: 1 !important;
}

.card-link{
  text-decoration: none;
  color: inherit;
}