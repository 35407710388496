.fade-img {
    transition: opacity 0.8s ease-in-out !important;
    position: absolute;
    opacity: 0;
    width: 40% !important;
    max-width: 300px !important;
}

.fade-img-sm {
    transition: opacity 0.8s ease-in-out !important;
    position: absolute;
    opacity: 0;
    margin-left: 10px;
    width: 90%;
    max-width: 400px;
    margin-top: 65px;
    max-height: 625px;
    /* width: 40% !important;
      max-width: 300px !important; */
  }

#scroll-down-chevron-md {
    display: block;
    position: relative;
    animation: updownAnimation 1s ease-in-out infinite alternate;
  }

  @media only screen and (max-width: 450px) {
    #home-collections-title {
      margin-top: 50px !important;
    }

    .hide-sm {
      display: none;
    }
  }


@keyframes updownAnimation {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-10px);
      /* Adjust the value to change the hover distance */
    }
  }