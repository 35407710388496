/* FadeInSection.css */
.fade-in-section {
    opacity: 0;
    transform: translateY(100px); /* Start with a slight upward movement */
    transition: opacity .5s ease-out, transform .5s ease-out;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0); /* Bring back to original position */
  }