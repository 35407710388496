:root{
    /* --background-color: #fff; */
    --background-color: #f8f8f8;
    --text-alpha: #000;
    --text-bravo: #fff;
    --button-color-alpha: #303132;
    --bs-table-bg: #000 !important;
}

/**
Style changes
*/
body{
    /* background-color: #000; Fallback color */
background-image: -webkit-linear-gradient(top, #000, #191919) !important; /* For Safari 5.1 to 6.0 */
background-image: -o-linear-gradient(top, #000, #191919) !important; /* For Opera 11.1 to 12.0 */
background-image: linear-gradient(to bottom, #000, #191919) !important; /* Standard syntax */
/* overflow-x: hidden; */

}

.site-contain{
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

span, h2{
    /* color: #ccc !important; */
    color: var(--text-alpha) !important;
}

.menu-txt{
    color: #ccc !important;
}

.ml-spacer{
    margin-left: 150px;
}

.container-fluid{
    /* background-color: #191919; */
    background-color: var(--background-color);
}
.main-menu > li > nav{
    background-color: #000;
    color: #fff !important;
}
.nav-item{
    display: flex;
    align-items: center;
}
.nav-link{
    color: #fff !important;
    font-weight: 500;
}

.nav-link-dropdwn:hover{
    color: #ffc107 !important;
}

.dashboard-list-group>label>li{
    width: 70%;
    background-color: #191919;
    color: #ccc; 
    margin-bottom: 5px;
    border: 1px solid !important;
    border-radius: 5px !important;
}

.dashboard-list-group>label{
    /* color: #ccc; */
    color: var(--text-alpha);
    margin-bottom: 20px;
    width: 70%;
}

.nav-link-lg{
    transition: width 0.1s ease;
    border-bottom: 0px solid;
    width: 100%;
    transition: 0.2s ease-in-out;
}

/* .nav-link-lg:hover{
    width: 100% !important;
    border-bottom: 1px solid !important;
} */
.nav-link-lg:hover{
    width: 100% !important;
    /* border-bottom: 1px solid !important; */
    color: #ffc107 !important
}

.nav-link-lg:hover::before{
    /* border-bottom: 1px solid; */
    width: 100%;
}

.nav-link-lg::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease;
}

.btn-alpha {
    color: var(--text-bravo);
    /* background-color: #ccc; */
    background-color: var(--button-color-alpha);
    font-weight: 400 !important;
}

.btn-alpha:hover{
    color: #fff;
    background-color: #6c757d;
}

.btn-bravo{
    /* color: #ccc; */
    color: #000;
}

.btn-bravo:hover{
    color: #fff;
    background-color: #6c757d;
}

.btn {
    border: 1px solid #000;
    font-weight: bold;
    position: relative;
    width: 50%;
}

.btn:hover{
    border: 2px solid #ff4d4f;
}


.active{
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

a:hover{
    cursor: pointer;
}

.list-link{
    /* background-color: #000; */
    background-color: var(--background-color);
    /* color: #ccc; */
    color: var(--text-alpha);
}

.list-group-item.active{
    background-color: #000;
    border-color: #ccc;
    color: #ffc107;
}

.list-link:hover{
    /* color: #ffc107; */
    font-weight: bold;
}

.card-button{
    border-radius: 0px;
    
}

.hoverable{
    overflow: hidden;
    
}

.active{
    text-decoration: none !important;
    width: 100%;
    border-bottom: 1px solid;
}

.hoverable:hover{
    /* border-right: 5px solid #0d6efd;
    border-bottom: 5px solid #0d6efd;
    border-radius: 12px; */
    /* transform: scale(1.25); */
}

.dropdown-toggle::after {
    margin-top: 50%;
}

.form-label{
    /* color: #ccc; */
    color: #000;
    width: 100%;
}

.grey-outline:hover{
    color: #000 !important;
    background-color: #ccc !important;
    border: 1px solid #ff4d4f;
}

.hoverable>div>img{
    transition: 0.3s all ease-in-out;
}

.hoverable>div>img:hover{
    transform: scale(1.09);
    cursor: pointer;
}
/* .hoverable>div>div>img{
    transition: 0.3s all ease-in-out;
}

.hoverable>div>div>img:hover{
    transform: scale(1.09);
    cursor: pointer;
} */

.card-outline{
    background: none;
    /* color: #ccc; */
    /* border: 2px solid #ccc; */
    border: 1px solid #000;
    border-radius: 5px;
}

.ant-ribbon-text{
    color: #fff !important;
}

.card-body{
    color: #000;
}

.card-body>h4{
    color: #000 !important;
}

.form-control{
    -webkit-box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 0px !important;
}


.mobile-side-menu{
    display: none;
    color: #ccc;
    transform: rotate(-90deg);
    position: absolute;
    border: 1px dotted;
    border-radius: 3px;
    padding: 5px 15px;
    left: -15px;
    margin-top: 40px;
    transition: 0.3s all ease-in-out;
    z-index: 500;
}

.mobile-list-group{
    transform: rotate(90deg);
}

.mobile-side-menu:hover{
    transform: rotate(-90deg) scale(1.00);
    cursor: pointer;
    
}

#dashboard-mobile-admin-menu-btn:hover{
    transform: rotate(-90deg) scale(1.02) !important;
}

.desktop-side-menu{
    
}

.mobile-menu-btn-open{
    left: 242px !important;
}
.mobile-menu-body-open{
    left: 48px !important;
}

.mobile-admin-menu-body-open{
    left: 20px !important;
}

.mobile-menu-btn{
    background-color: gray;
    color: #000;
    font-weight: bold;
    font-size: large;
}

.mobile-admin-menu-btn-open{
    left: 226px !important;
}

#dashboard-mobile-admin-menu-btn{
    margin-top: 55px;
}

#dashboard-mobile-menu-body{
    position: absolute;
    left: -215px;
    background-color: #191919;
    color: #fff;
    font-weight: bold;
    font-size: large;
    margin-top: -28px;
}

#filter-options-mobile-menu-body{
    position: absolute;
    left: -380px;
    background-color: #191919;
    color: #fff;
    font-weight: bold;
    font-size: large;
    margin-top: -28px;
}

#dashboard-mobile-menu-body:hover{
    transform: rotate(-90deg) scale(1.00) !important;
}

.mobile-menu-items{
    background-color: #191919;
    color: #ccc;
}

.mobile-menu-items:hover{
    /* S */
}

.overlay-active{
    height: 100vh;
    width: 100vw;
    position: fixed;
}

@media (max-width: 768px){
    .desktop-side-menu{
        display: none !important;
    }
    .mobile-side-menu{
        display: block !important;

    }
}


.sm-hamburger:hover{
    cursor: pointer;
}

.mobile-menu-container{
    /* background: #333; */
    background: grey;
    position: absolute;
    width: 100vw;
    height: 0vh;
    /* width: 0vw; */
    transition: all 0.3s ease-in-out;
    margin-top: -8px;
}

@media only screen and (min-width: 768px) {
    /* Your CSS rules for screens 400px or less */
    /* For example: */
    .mobile-menu-container{
        display: none;
    }
  }



.mobile-menu-container>ul{
    display: none;
    color: #ccc;
    list-style: none;
    margin-left: 0px;
    padding-left: 0;
}

.mobile-menu-container>ul>li{
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: larger;
    font-weight: 500;
}

.mobile-menu-container>ul>li>a{
    text-decoration: none;
    /* color: #ccc; */
    color: #fff;
}

.mobile-menu-container>ul>li>a:hover{
    /* color: #ffc107 !important */
    color: gold;
}

.mobile-menu-item:hover{
    color: gold !important;
}

.mobile-menu-item.active{
    color: gold !important;
}

.mobile-menu-container>ul>li>.active{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: gold;
}

.mobile-menu-open{
    height: 100vh;
    z-index: 1000;
    /* width: 100vw; */
    overflow-y: auto;
}

.mobile-menu-open >ul{
    display: block !important;
}


.display-row::-webkit-scrollbar {
    width: 12px;
    height: 10px;
}

.display-row::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}
.display-row::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
    background: #191919 !important;
}


@media only screen and (max-width: 400px) {
    /* Your CSS rules for screens 400px or less */
    /* For example: */
    .display-row{
        padding: none;
    }
    
  }

  @media only screen and (min-width: 401px) {
    /* Your CSS rules for screens 400px or less */
    /* For example: */
    .display-row{
        padding: 0px;
    }
    
  }

  .footer-section {
    width: 80%;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-alpha);
}

.footer-links > a {
    /* color: #ccc; */
    text-decoration: none;
    margin-top: 5px;
    color: var(--text-alpha);
}

.footer-links > a:hover {
    text-decoration: underline;
}

.footer-links > a > svg {
    background-color: var(--background-color);
    fill: var(--text-alpha);
    height: 30px;
    width: 30px;
}

.footer-links > a:hover > svg {

    fill: #6c757c;

}

.overflow-y-hidden{
    overflow-y: hidden !important;
}

.page-component{
    /* background-color: #191919; */
    background-color: var(--background-color) !important;
    color: #ccc;
}

.color-ccc{
    color: #ccc;
}

label{
    /* color: #ccc; */
    color: #000;
}

h4{
    color: #ccc;
}

.grey{
    color: #ccc;
}

th{
    /* color: #ccc; */
    color: var(--text-alpha);
    /* background-color: #fff; */
}

.table{
    --bs-table-bg: #191919 !important;
    --bs-table-color: #fff;
}

.related{
    max-width: 280px;
}

.logo-lg-screen{
    max-width: 40px;
}

.main-menu{
    --bs-nav-link-padding-y: 10px !important;
    background-color: #000;
}

.splash-charlie{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    max-width: 45% !important;
    transition: opacity 2s ease-out, transform 1s ease-out;
    opacity: 0;
}

.fade-in{
    opacity: 1 !important;
}

.splash-text-contain-box{
    /* border: 1px solid; */
    border-radius: 8px;
    padding: 25px;
    background-color: #e7e3d9;
    position: absolute;
    left: 50%;
  top: 60%;              /* Move the element to the middle from the top */
  transform: translate(-50%, 100%);  /* Shift it both horizontally and vertically */
    opacity: 0.9;
    color: #303132;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    /* transform: translateY(100%); */
  transition: transform 1s ease-out;
}

.splash-text-contain-box-sm{
    position: relative !important;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    background-color: #e7e3d9;
    margin-top: 50vh;
    opacity: 0.7;
    padding: 15px;
    border-radius: 8px;
}

.float-up{
    transform: translate(-50%, 0);
}


.btn-charlie{
    background-color: #303132;
    color: #ccc;
}

.btn-charlie:hover{
    background-color: #fff !important;
    color: #303132 !important;
    border: 1px solid #000;
}

@media only screen and (max-width: 1300px){
    .splash-text-contain-box{
        top: 42% !important;
    }
}

.ant-scroll-number-only-unit{
    color: #fff !important;
}

.featured-product-card{
    /* border: 1px solid red; */
    transition: all 0.2s ease-in-out;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-left: 0px;
    padding-right: 0px;
}

.featured-product-card:hover{
    transform: scale(1.25);
    cursor: pointer;
    border: 1px solid #000;
    max-width: 50%;
}

@media only screen and (min-width: 1200px){
    .featured-product-card{
        max-width: 15% !important;
    }
}



.view-featured-overlay{
    transition: all 0.4s ease-in-out;
    opacity: 0;
    background: #000;
    width: 100%;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* position: absolute;
    margin-top: -20px; */
}


.featured-product-card:hover .view-featured-overlay{
 opacity: 1;    
}

.ant-checkbox {
    border: 1px solid #000 !important;
}

.ant-radio-inner{
    border: 1px solid #000 !important;
}

.h4{
    color: var(--text-alpha) !important;
}

.no-underline{
    text-decoration: none !important;
}

.ant-select-arrow{
    display: none !important;
}

.admin-header{
    font-size: 32px;
    margin-top: -50px !important;
    margin-bottom: 50px;
}

.table>:not(caption)>*>*{
    background-color: #fff !important;
    color: #000 !important;
}

.about-us-square-img{
    margin-top: 100px;
    max-width: 70%;
    border-radius: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    
}

@media only screen and (min-width: 992px){
    .about-us-square-img{
        max-width: 550px !important;
    }
}

.product-view-card{
    max-width: 320px;
}

@media only screen and (min-width: 768px){
    .product-view-card{
        max-width: 500px;
    }
}


.mw-300{
    min-width: 300px;
}

@media only screen and (max-width: 468px){
    .mw-300{
        /* max-width: 20% !important; */
        width: 42% !important;
        min-width: 100px;
    }
}

.h-auto{
    height: auto !important;
}

.sticky-top{
    position: sticky;
    top: 0;
}

#top-menu-lg{
    transition: all 0.3s ease-in-out;
}

.sm-menu-logo{
    width: 70px;
}

.scrolling-sm-menu-logo{

}


/* Shopping Cart */
#top-menu-sm.scrolling>div>li>span>a>svg{
    font-size: 2.0em;
    margin-top: -40px;
}

/* Shopping Cart Items Number Display */
#top-menu-sm.scrolling>div>li>span>sup{
    margin-top: -5px !important;
}

/* LOGO center */
/* #top-menu-sm.scrolling>div>.logo-cntnr-sm>a>.sm-menu-logo{ */
#top-menu-sm.scrolling>div>a>img{
    width: 20px !important;
}

/* Menu Container*/
#top-menu-sm.scrolling>div>div{

    border: none !important;
    margin-top: 0px !important;
    display: flex !important;
    flex-direction: row !important;
}

/* Menu Text*/
#top-menu-sm.scrolling>div>div>span{

    font-size: small !important;
    margin-top: -5px !important;
    font-size: small !important;
}

/* Menu Hamburger*/
#top-menu-sm.scrolling>div>div>svg{
    width: 20px !important;
    height: 20px !important;
    margin-top: -5px;
    /* display: none; */
}