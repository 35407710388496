.centerBox{
    position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 200px;   */
  /* height: 100px;  */
  background-color: lightblue;
  text-align: center;
  line-height: 40px; /* Optional: for vertically centering text */
  padding: 10px;
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  border-radius: 10px;
  z-index: 2;
}

.cfm-bx-btn{
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.btn-ctnr{
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.0rem;
}
/* border: "1px solid red", display: "flex", flexDirection: "column" */