.contain{
    background-color: #191919;
    padding: 2.5rem;
}

.font{
    color: #ccc;
}

.small{
    font-size: small;
}

.body{
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    border-radius: .5rem;
    padding: .8rem;
    min-height: 10rem;
}

.border{
    padding: .5rem;
    max-width: 700px;
    border: none !important;
    margin-left: auto;
    margin-right: auto;
}

