

.key-img-0{
    /* margin-left: 10% !important; */
}

/* Media Queries Needed for margin-left rules. Must be responsive */

@media only screen and (max-width: 600px){
    .key-img-0{
        margin-left: 250% !important;
    }
}

@media only screen and (min-width: 600px){
    .key-img-0{
        margin-left: 195% !important;
    }
}

@media only screen and (min-width: 768px){
    .key-img-0{
        margin-left: 95% !important;
    }
}

@media only screen and (min-width: 992px){
    .key-img-0{
        margin-left: 40% !important;
    }
}


@media only screen and (min-width: 1200px){
    .key-img-0{
        margin-left: 30% !important;
    }
}

@media only screen and (min-width: 1400px){
    .key-img-0{
        margin-left: 30% !important;
    }
}