.App {
    text-align: center;
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 1rem;
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    /* background-color: #191919; */
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  div {
    /* margin-bottom: 1rem; */
  }
  
  label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  input,
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }

  textarea{
    /* width: 108%; */
    min-height: 120px;
  }
  
  button {
    padding: 0.75rem;
    font-size: 1rem;
    color: darkred;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 22rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  form{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  form>div{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-right: auto;
    margin-left: auto;
    margin-top: .5rem;
    width: 100%;

  }


  